export default [
    {
      title: "Nav.Home",
      route: "Home",
      icon: "fad fa-home",
      permission: "ALL"
    },
    {
      title: "Nav.Lesson",
      route: "Lessons",
      icon: "fad fa-books",
      permission: "ALL"
    },
    {
      title: "Nav.Exam",
      route: "Exams",
      icon: "fad fa-clipboard-list",
      permission: "ALL"
    },
    {
      title: "Nav.Reps",
      route: "Watch",
      icon: "fad fa-play-circle",
      permission: "ALL"
    },
    {
      title: "Nav.Calendar",
      route: "Calendar",
      icon: "fad fa-calendar-star",
      permission: "ALL"
    },
    {
      title: "Nav.Target",
      route: "Targets",
      icon: "fad fa-bullseye-pointer",
      permission: "ALL"
    },
    {
      title: "Nav.Missing",
      route: "Missings",
      icon: "fad fa-chalkboard-teacher",
      permission: "ALL"
    },
    {
      title: "Nav.HomeWork",
      route: "Homework",
      icon: "fas fa-briefcase",
      permission: "ALL"
    },
    {
      title: "Nav.Report",
      route: "Reports",
      icon: "fad fa-user-chart",
      permission: "ALL"
    },
    {
      title: "Nav.Statistic",
      route: "Statistics",
      icon: "fad fa-chart-pie-alt",
      permission: "ALL"
    },
    {
      title: "Nav.Guidance",
      route: "Guidance",
      icon: "fad fa-hands-helping",
      permission: "ALL"
    },
    {
      title: "Nav.Reply",
      route: "Replys",
      icon: "fad fa-question",
      permission: "ALL"
    },
    {
      title: "Nav.Message",
      route: "Messages",
      icon: "fad fa-comments",
      permission: "ALL"
    }, 
  
    // {
    //   title: "Nav.Notability",
    //   route: "Reports",
    //   icon: "far fa-quote-left",
    //   permission: "ALL"
    // },
  ];
  